import { CDN_LINK } from "@globalConfig";
import { trackEvents } from "@/common/events/events";
import { createInternalUrlWithParams } from "@/utils/common";
import { logout } from "@/utils/auth";

const menuData = (t, loggedin, user, lang) => [
  {
    sectionName: t("حسابي", { ns: "web_aside_menu" }),
    show: loggedin,
    subLinks: [
      {
        link: null,
        imageBlue: CDN_LINK + "/assets/images/round-person-24px (2)@1Blue.svg",
        image: CDN_LINK + "/assets/images/round-person-24px (2)@1.svg",
        title: t("معلوماتي", { ns: "web_aside_menu" }),
        linkFunc: (e, navigate) => {
          e.preventDefault();
          navigate(
            createInternalUrlWithParams("/user/account/my-info", {}, lang)
          );
        },
      },
      {
        link: createInternalUrlWithParams(`/tracking-landing`, {}, lang),
        imageBlue: CDN_LINK + "/assets/images/location_on-24px@1Blue.svg",
        image: CDN_LINK + "/assets/images/location_on-24px@1.svg",
        title: t("تتبع طلباتي", { ns: "web_aside_menu" }),
      },
      {
        link: createInternalUrlWithParams(`/user/favorite`, {}, lang),
        imageBlue: CDN_LINK + "/assets/images/favorite-Blue.svg",
        image: CDN_LINK + "/assets/images/aside-favorite.svg",
        title: t("المفضلة", { ns: "web_aside_menu" }),
        scale: "scale(1.3)",
      },
      {
        link: createInternalUrlWithParams(`/auth-redirect/null`, {}, lang),
        //link: createInternalUrlWithParams(`/`, {}, lang),
        imageBlue: CDN_LINK + "/assets/images/Sign-out-Blue.svg",
        image: CDN_LINK + "/assets/images/round-exit_to_app-24px@1.svg",
        title: t("تسجيل الخروج", { ns: "web_aside_menu" }),
        scale: "scale(1.3)",
        linkFunc: () => {
          trackEvents("user_logout", {
            user_id: `966${user.phone_number?.slice(1)}`,
            ui_language: lang,
          });
          logout();
        },
      },
    ],
  },
  {
    sectionName: t("خدماتنا", { ns: "web_aside_menu" }),
    show: true,

    subLinks: [
      {
        link: createInternalUrlWithParams("/autos", {}, lang),
        imageBlue:
          CDN_LINK + "/assets/images/sideMenu/BlueIcons/all_cars_icon_Blue.svg",
        image: CDN_LINK + "/assets/images/sideMenu/all_cars_icon.svg",
        title: t("سيارات ومركبات", {
          ns: "web_aside_menu",
        }),
        scale: "scale(1.8)",
      },
      {
        link: createInternalUrlWithParams("/offerspage", {}, lang),
        imageBlue:
          CDN_LINK + "/assets/images/sideMenu/BlueIcons/offers_icon_Blue.svg",
        image: CDN_LINK + "/assets/images/sideMenu/offers_icon.svg",
        title: t("عروض السيارات", {
          ns: "web_aside_menu",
        }),
        scale: "scale(1.6)",
      },
      {
        link: createInternalUrlWithParams(
          "/filters",
          {
            ["condition_id"]: 0,
          },
          lang
        ),
        imageBlue: CDN_LINK + "/assets/images/UsedCarsOffersBlue.svg",
        image: CDN_LINK + "/assets/images/UsedCarsOffers.svg",
        title: t("عروض السيارات المستعملة", {
          ns: "web_aside_menu",
        }),
        scale: "scale(1.3)",
      },
      {
        link: createInternalUrlWithParams(
          "/filters",
          {
            ["condition_id"]: 1,
          },
          lang
        ),
        imageBlue: CDN_LINK + "/assets/images/NewCarsOffersBlue.svg",
        image: CDN_LINK + "/assets/images/NewCarsOffers.svg",
        title: t("عروض السيارات الجديدة", {
          ns: "web_aside_menu",
        }),
        scale: "scale(1.3)",
      },
      {
        link: createInternalUrlWithParams("/tracking-landing", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/location_on-24px@1Blue.svg",
        image: CDN_LINK + "/assets/images/location_on-24px@1.svg",
        title: t("تعقب طلب سيارتك", { ns: "web_aside_menu" }),
      },

      {
        link: createInternalUrlWithParams("/تقسيط-سيارات", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/Finance@1Blue.svg",
        image: CDN_LINK + "/assets/images/Finance@1.svg",
        title: t("تمويل سيارات", { ns: "web_aside_menu" }),
        scale: "scale(1.1)",
      },
    ],
  },
  {
    sectionName: t("موقع سيارة", { ns: "web_aside_menu" }),
    show: true,

    subLinks: [
      {
        link: createInternalUrlWithParams("/contact-us", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/Call Us@1Blue.svg",
        image: CDN_LINK + "/assets/images/Call Us@1.svg",
        title: t("اتصل بنا", { ns: "web_aside_menu" }),
      },
      {
        link: createInternalUrlWithParams("/about-us", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/Who We Are@1Blue.svg",
        image: CDN_LINK + "/assets/images/Who We Are@1.svg",
        title: t("من نحن", { ns: "web_aside_menu" }),
        scale: "scale(1.2)",
      },
      {
        link: createInternalUrlWithParams("/about-us/services", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/services_Blue_icon.svg",
        image: CDN_LINK + "/assets/images/services_icon.svg",
        title: t("خدماتنا", { ns: "web_aside_menu" }),
        scale: "scale(1.5)",
      },
      {
        link: createInternalUrlWithParams("/trend", {}, lang),
        imageBlue:
          CDN_LINK + "/assets/images/sideMenu/BlueIcons/trend_icon_Blue.svg",
        image: CDN_LINK + "/assets/images/sideMenu/trend_icon.svg",
        title: t("سيارة ترند", { ns: "web_aside_menu" }),
        scale: "scale(1.5)",
      },
      {
        link: createInternalUrlWithParams("/car-showrooms", {}, lang),
        imageBlue:
          CDN_LINK + "/assets/images/sideMenu/BlueIcons/Dealers@1Blue.svg",
        image: CDN_LINK + "/assets/images/sideMenu/Dealers@1.svg",
        title: t("معرض سيارة", { ns: "web_aside_menu" }),
        scale: "scale(1.5)",
      },
      {
        link: createInternalUrlWithParams("/city", {}, lang),
        imageBlue:
          CDN_LINK + "/assets/images/sideMenu/BlueIcons/cities_icon_Blue.svg",
        image: CDN_LINK + "/assets/images/sideMenu/cities_icon.svg",
        title: t("المدن", { ns: "web_aside_menu" }),
        scale: "scale(1.5)",
      },
      {
        link: "https://syarah.com/carsguide/",
        isExternal: true,
        imageBlue: CDN_LINK + "/assets/images/car_guide_Blue_icon.svg",
        image: CDN_LINK + "/assets/images/car_guide_icon.svg",
        title: t("دليل سيارة", { ns: "web_aside_menu" }),
        scale: "scale(1.5)",
        hide: lang === "en",
      },
      {
        link: createInternalUrlWithParams("/network", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/affiliate_Blue_icon.svg",
        image: CDN_LINK + "/assets/images/affiliate_icon.svg",
        title: t("برنامج سيارة للتسويق بالعمولة", {
          ns: "web_aside_menu",
        }),
        scale: "scale(1.5)",
      },
      {
        link: createInternalUrlWithParams("/service-centers", {}, lang),
        imageBlue: CDN_LINK + "/assets/images/111service_centers2_iconBlue.svg",
        image: CDN_LINK + "/assets/images/111service_centers2_icon.svg",
        title: t("مراكز الصيانة و الخدمات", {
          ns: "web_aside_menu",
        }),
        scale: "scale(1.5)",
      },
      {
        link: "https://apply.workable.com/syarah/",
        isExternal: true,
        imageBlue: CDN_LINK + "/assets/images/Jobs@1Blue.svg",
        image: CDN_LINK + "/assets/images/Jobs@1.svg",
        title: t("وظائف", { ns: "web_aside_menu" }),
        scale: "scale(1.1)",
      },
    ],
  },
];

export default menuData;
